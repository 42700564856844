import LandingPagePhotoDesktop1 from "./desktop_landing_page_CROPPED (1).webp";
import LandingPagePhotoDesktop2 from "./desktop_landing_page_CROPPED (2).webp";
import LandingPagePhotoDesktop3 from "./desktop_landing_page_CROPPED (3).webp";
import LandingPagePhotoDesktop4 from "./desktop_landing_page_CROPPED (4).webp";
import LandingPagePhotoDesktop5 from "./desktop_landing_page_CROPPED (5).webp";
import LandingPagePhotoDesktop6 from "./desktop_landing_page_CROPPED (6).webp";
export const landingPagePhotos = [
  LandingPagePhotoDesktop1,
  LandingPagePhotoDesktop2,
  LandingPagePhotoDesktop3,
  LandingPagePhotoDesktop4,
  LandingPagePhotoDesktop5,
  LandingPagePhotoDesktop6,
];
