import Screenshot1 from "./ScreenShot (1).webp";
import Screenshot2 from "./ScreenShot (3).webp";
import Screenshot3 from "./ScreenShot (2).webp";
import Screenshot4 from "./ScreenShot (4).webp";
import Screenshot5 from "./ScreenShot (5).webp";
import Screenshot6 from "./ScreenShot (6).webp";
import Screenshot7 from "./ScreenShot (7).webp";
import Screenshot8 from "./ScreenShot (8).webp";
import Screenshot9 from "./ScreenShot (9).webp";
import Screenshot10 from "./ScreenShot (10).webp";
import Screenshot11 from "./ScreenShot (11).webp";
import Screenshot12 from "./ScreenShot (12).webp";

export const mobileProjectPhotos = [
  Screenshot1,
  Screenshot2,
  Screenshot3,
  Screenshot4,
  Screenshot5,
  Screenshot6,
  Screenshot7,
  Screenshot8,
  Screenshot9,
  Screenshot10,
  Screenshot11,
  Screenshot12,
];
