import RealEstateDesktop1 from "./realestate (1).webp";
import RealEstateDesktop2 from "./realestate (2).webp";
import RealEstateDesktop3 from "./realestate (3).webp";
import RealEstateDesktop4 from "./realestate (4).webp";
import RealEstateDesktop5 from "./realestate (5).webp";
import RealEstateDesktop6 from "./realestate (6).webp";
import RealEstateDesktop7 from "./realestate (7).webp";
import RealEstateDesktop8 from "./realestate (8).webp";
import RealEstateDesktop9 from "./realestate (9).webp";
import RealEstateDesktop10 from "./realestate (10).webp";


export const realEstateProjectPhotos = [
  RealEstateDesktop1,
  RealEstateDesktop2,
  RealEstateDesktop3,
  RealEstateDesktop4,
  RealEstateDesktop5,
  RealEstateDesktop6,
  RealEstateDesktop7,
  RealEstateDesktop8,
  RealEstateDesktop9,
  RealEstateDesktop10,
];
